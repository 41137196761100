import './App.css';
import QRCode from './QRCode';
import React, { useState, useEffect } from 'react'
import request from 'superagent';


function App() {



  // const [qrCode, setQRCode] = useState();
  // useEffect(() => {
  
    // const interval = setInterval(() => {
    //   setQRCode("bankid.499810e8-cf44-4729-b4fb-78c11e08f26b.0.23d93fa6a7f2ea8b5ffb4a25875b91849d34e174b9b6512755d3e536cb5c28c");
      
    //   const start = () => Axios.get(
    //     `https://qrcodegenerator.gocg.workers.dev/`,
    //     { withCredentials: true },
    //   );

    //   start();
    // }, 1000);
  
  //   return () => clearInterval(interval);

  // }, []);
  return (
    <div className="App">     

      <div className="filterContainer">
        <div className="logo"></div>
        <div>SÖK</div>
        <input className="searchBar" type="text" placeholder="Sök, företagsnamn, taggar, fritext"/>
        <div>Sortera</div>
        <div className="filter" >
          <select>
            <option value="filter1">Populärast</option>
            <option value="filter2">A till Ö</option>
            <option value="filter3">Ö till A</option>
            <option value="filter4">Nyast</option>
            <option value="filter5">Äldst</option>
          </select>
        </div>
        <div>Filter1</div>
        <div className="filter" >
          <select>
            <option value="filter1">Populärast</option>
            <option value="filter2">A till Ö</option>
            <option value="filter3">Ö till A</option>
            <option value="filter4">Nyast</option>
            <option value="filter5">Äldst</option>
          </select>
        </div>
        
        {/* <dic className="container2">
          <div className="filters">
          <div className="filter">
              <div>Sortera</div>
              <select>
                <option value="filter2">Populärast</option>
                <option value="">A till Ö</option>
                <option value="filter1">Ö till A</option>
                <option value="filter3">Nyast</option>
                <option value="filter4">Äldst</option>
              </select>
            </div>
            <div className="filter">
              <div>Filtrera</div>
              <select>
                <option value="">Filtrera typ av företag</option>
                <option value="filter1">Filter 1</option>
                <option value="filter2">Filter 2</option>
                <option value="filter3">Filter 3</option>
                <option value="filter4">Filter 4</option>
              </select>
            </div>
          </div>
        </dic> */}
      </div>
      <div className="grid-container">
        {/* { qrCode && ( <QRCode qrCode={qrCode} /> )} */}
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
      </div>
    </div>
  );
}

export default App;
